import axios from "axios";
const instance = axios.create({
    timeout: 1000,
});
axios.interceptors.request.use(function (config) {
    return config;
}, function (error) {
    return Promise.reject(error);
});

axios.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    return Promise.reject(error);
});
axios.defaults.timeout = 5000;
export default axios

