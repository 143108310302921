<template>
  <div class="home">
    <div>
        <div class="toptitle">
          COFFEEVERSE MEMBERSYSTEM
      </div>
      <div class="imageicon">
          <img class="imageiconbox" src="../../public/images/金色logo@3x.png" alt="">
      </div>
    </div>
    <div class="inputphonebox" >
      <input class="inputbox" type="text" v-model="phonenumber" placeholder="Please enter your mobile number" ><button  @click="checkphone()" class="phonebox">Login</button>
      <div class="waringtitle" v-if="phonerules">* You typed the phone format incorrectly</div>
   
    </div>
    
   
  </div>
</template>

<script>
import {pushphone} from "../axios/index"

import { Toast } from 'vant';


export default {
  name: 'HomeView',
  data(){
    return {
      phonenumber:"",
      phonerules:false,
     
      
    }
  },
  components: {
     Toast
  },
  methods:{
    checkphone(){
      let num = /^((\+66|0)(\d{1,2}\-?\d{3}\-?\d{3,4}))$/
     
        

      if(!num.test(this.phonenumber)){
        this.phonerules = true
      }else{
        pushphone({mobile:this.phonenumber}).then(res=>{
          console.log(res)
        })
       
          Toast.success('login successfully');
          this.$router.push({path:`/integralmember/${this.phonenumber}`})
        
      }
    }
  }
}
</script>


<style lang="scss" scoped >
.home{
 width: 100%;
 height: 100%;
 background-image: url('../../public/images/编组@3x.png') ;
 background-size: 100% 100%; 
 background-position: center center ; 
 display: flex;
 justify-content: space-between;
 flex-direction: column;
    .toptitle{
      width: 100%;
      height: 1.7rem;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 0.52rem;
      color: #D1A36E;
    
    }

}

.imageiconbox{
  width: 35%;
  height: 78%;
}
.inputphonebox{
  position: relative;
  width: 100%;
  height: 1.55rem;

  margin-bottom: 1.0rem;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.inputbox{
  width: 65%;
  height: 68%;
  border: none;
  border-radius: 0.2rem;
  padding-left: 0.3rem;
  font-size: 0.35rem;
  letter-spacing:0.03rem;
  outline: none;

}
.phonebox{
  width: 22%;
  height: 78%;
  border-radius: 0.2rem;
  outline: none;
  border: none;
  font-size: 0.45rem;
  color: #fff;
   background-image: linear-gradient(#FFC393, #D1A36E);
}
.phonebox:active{
   background: #ffff;
   color: #FFC393;
}
.waringtitle{
  position: absolute;
  top: 1.5rem;
  color: #fff;
  font-size: 0.2rem;
  left: 0.35rem;
}

</style>
